import { CrudModel } from "./_crud";

export class UserAdminModel extends CrudModel {
	constructor() {
		super("admin/user");
	}

	async getProfile() {
		return this.get("profile");
	}

	async updatePassword(currentPassword: string, password: string) {
		return this.patch(null, "password", { currentPassword, password });
	}
}

export default new UserAdminModel();
