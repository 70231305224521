








import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import userAdminModel from "@/api/user.admin.model";
import AdminUpdatePassword from "./AdminUpdatePassword.vue";

@Component({
	components: { EntityCrud, AdminUpdatePassword },
})
export default class Admins extends Vue {
	model: any = userAdminModel;

	title = "Administradores";
	tableColumns = ["email", "name"];
	filter = { role: "ADMINISTRATOR" };

	formColumns = ["email", "name", { key: "password", createOnly: true }, "profilePictureUrl"];
	defaultEntity = { role: "ADMINISTRATOR" };

	userData: any = {};

	async mounted() {
		this.userData = await this.$store.dispatch("auth/getUserData");
	}
}
