












































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import accessObject from "lodash.get";
import { BButton, BFormCheckbox, BFormFile, BFormGroup, BFormInput, BFormTextarea, BLink } from "bootstrap-vue";
import { format, formatISO, parse, parseISO } from "date-fns";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/flatpickr.css";

import { FieldDescription } from "@/api/_crud";
import { uploadFile } from "@/api/_request";
import { mimeTypes, removeDiacritics, showErrorAlert } from "@/helpers";
import { AllowedMimeTypes } from "@/interfaces/mime";

type Clauses = "every" | "orSome";

interface ClauseProps {
	field: string;
	equalsTo: string | boolean;
	isFilled: true;
}

type VisibleWhenProps = {
	[key in Clauses]?: ClauseProps[];
};

@Component({
	components: { flatPickr, BButton, BFormGroup, BFormCheckbox, BFormFile, BFormInput, BFormTextarea, BLink, vSelect },
})
export default class EntityField extends Vue {
	// Input option 1: field object from entity description
	@Prop() field?: any;

	// Input option 2: full entity description and field key
	@Prop() entityDescription?: FieldDescription[];
	@Prop() fieldKey?: string;

	@Prop() entity: any;
	@Prop({ default: () => [] }) entityRelations: {
		label: string;
		options: Array<{
			id: string;
			label: string;
		}>;
	}[];
	@Prop({ default: false }) readOnly: boolean;

	get visible() {
		if (this.fieldDefinition.visibleWhen) {
			const { orSome: orSomeClause, every: everyClause } = this.fieldDefinition.visibleWhen as VisibleWhenProps;

			if (everyClause && orSomeClause) {
				return orSomeClause.some(this.handleFieldVisibility) || everyClause.every(this.handleFieldVisibility);
			} else if (orSomeClause) {
				return orSomeClause.some(this.handleFieldVisibility);
			} else if (everyClause && !orSomeClause) {
				return everyClause.every(this.handleFieldVisibility);
			} else {
				return true;
			}
		}

		return true;
	}

	get flatPickrConfig() {
		return {
			locale: Portuguese,
			enableTime: this.fieldDefinition.kind === "datetime",
			dateFormat: "Z",
			altInput: true,
			altFormat: this.fieldDefinition.kind === "datetime" ? "d/m/Y H:i" : "d/m/Y",
			allowInput: true,
		};
	}

	file: any = null;

	month = "";
	year = "";

	get fieldDefinition() {
		if (this.field) {
			return this.field;
		}
		if (this.entityDescription && this.fieldKey) {
			return this.entityDescription.find(field => field.key === this.fieldKey);
		}
		return { model: {} };
	}

	async mounted() {
		await this.loadManyToOneOptions();
		this.loadStaticDataToEntity();
	}

	handleFieldVisibility({ field, equalsTo, isFilled }: ClauseProps) {
		if (isFilled) {
			return accessObject(this.entity, field);
		}

		const [relation, key] = field.split(".");

		if (key) {
			if (key !== "name") {
				console.error(`Cannot validate "${field}", only ${relation}.name`);
				return false;
			}

			return this.entityRelations.some(
				entityField =>
					entityField.label === relation &&
					entityField.options.find(
						(option: any) =>
							option.id === accessObject(this.entity, `${relation}.id`) &&
							removeDiacritics(option.label) === removeDiacritics(String(equalsTo)),
					),
			);
		}

		return accessObject(this.entity, field) === equalsTo;
	}

	@Watch("fieldDefinition")
	async fieldDefinitionChanged() {
		await this.loadManyToOneOptions();
	}

	async loadManyToOneOptions() {
		if (this.fieldDefinition.kind === "relation") {
			await this.fieldDefinition.model.loadManyToOneOptions();

			if (!this.entityRelations.find(field => field.label === this.fieldDefinition.key)) {
				this.entityRelations.push({
					label: this.fieldDefinition.key,
					options: this.fieldDefinition.model.manyToOneOptions,
				});
			}
		}
	}

	@Watch("file")
	async fileChanged() {
		if (!this.fieldDefinition.key) {
			return;
		}
		// limpa o arquivo se ele foi removido
		if (!this.file) {
			this.entity[this.fieldDefinition.key] = null;
			return;
		}
		// faz o upload e atualiza a entidade com a URL
		this.$store.dispatch("app/showLoading");
		try {
			const fileResponse = await uploadFile(this.file, "", {
				allowedMimeTypes: mimeTypes[(this.fieldDefinition.allowedMimeTypes as AllowedMimeTypes) ?? "images"],
			});
			this.entity[this.fieldDefinition.key] = fileResponse?.fileName;
		} catch (error: any) {
			this.file = null;
			this.entity[this.fieldDefinition.key] = null;
			showErrorAlert(error?.message);
		}
		this.$store.dispatch("app/hideLoading");
	}

	openFile(fileUrl: string) {
		window.open(fileUrl, "_blank");
	}

	get entityValue() {
		return this.entity[this.fieldDefinition.key];
	}

	// Date Mask
	dateMaskValue = "";

	@Watch("visible")
	clearHiddenField() {
		if (!this.visible) {
			delete this.entity[this.fieldDefinition.key];

			if (this.fieldDefinition.kind === "relation" && this.fieldDefinition.relationType === "many-to-one") {
				delete this.entity[`${this.fieldDefinition.key}.id`];
			}
		}
	}

	@Watch("entityValue")
	loadStaticDataToEntity() {
		if (this.entityValue) {
			switch (this.fieldDefinition.kind) {
				case "datemask":
					this.dateMaskValue = format(parseISO(this.entity[this.fieldDefinition.key]), "dd/MM/yyyy");
					break;

				case "month":
					this.month = this.entity[this.fieldDefinition.key];
					break;

				case "year":
					this.year = this.entity[this.fieldDefinition.key];
					break;
			}
		}
	}

	@Watch("dateMaskValue")
	updateEntityFromDateMask() {
		if (this.dateMaskValue?.length !== 10) {
			return;
		}
		try {
			this.entity[this.fieldDefinition.key] = formatISO(
				parse(this.dateMaskValue, "dd/MM/yyyy", new Date()).setHours(12),
			);
		} catch {
			showErrorAlert("Data inválida. Digite novamente no formato dd/mm/aaaa.");
			this.entity[this.fieldDefinition.key] = null;
			this.dateMaskValue = "";
		}
	}

	@Watch("month")
	updateMonthFromEntity() {
		if (Number(this.month) > 0 && !(Number(this.month) > 0 && Number(this.month) <= 12)) {
			showErrorAlert("Mês inválido. Tente valores entre 1 a 12");
		}

		this.entity[this.fieldDefinition.key] = this.month;
	}

	@Watch("year")
	updateYearFromEntity() {
		if (this.year.length === 4 && !this.year.match(/1[5-9][0-9]{2}|2[0-9]{3}/)) {
			showErrorAlert("Ano inválido. Tente valores entre 1500 a 2999");
		}

		this.entity[this.fieldDefinition.key] = this.year;
	}
}
