




































































import { BButton, BTable, BPagination, BOverlay, BFormInput } from "bootstrap-vue";
import { format, parseISO } from "date-fns";
import debounce from "debounce";
import * as Slugify from "slugify";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import XLSX from "xlsx";

import { FieldDescription } from "@/api/_crud";
import { removeDiacritics } from "@/helpers";

const slugify: any = Slugify;

@Component({
	components: {
		BButton,
		BFormInput,
		BTable,
		BPagination,
		BOverlay,
	},
})
export default class EntityTable extends Vue {
	@Prop() title: string;
	@Prop() model: any;
	@Prop() columns: (string | { key: string; name: string; valueTransform: Function })[];
	@Prop() exportColumns: (string | { key: string; name: string; valueTransform: Function })[];
	@Prop({ default: 15 }) pageLimit: number;
	@Prop({ default: () => {} }) filter: any;
	@Prop({ default: true }) enableCreation: boolean;
	@Prop({ default: undefined }) initialSort: { key: string; direction: "ASC" | "DESC" };
	@Prop({ default: false }) readOnly: boolean;
	@Prop({ default: false }) multiple: boolean;
	@Prop({ default: false }) preventNavigation: boolean;
	@Prop({ default: false }) showRowNumber: boolean;
	@Prop({ default: () => [] }) highlightRowsWithFilters: { filter: any; backgroundColor: string }[];

	@Prop({ default: () => [] }) actions: {
		name: string;
		icon: string;
		color: string;
		action: any;
	}[];

	specialKeys = ["rowNumber", "selected", "actions"];

	entityDescription: any = [];

	// current search state
	isTableLoading = false;
	currentPage: number = 1;
	currentSearchTerm: string = "";
	currentSortKey: string = "";
	currentSortDesc: boolean = false;
	currentTotal: number = 0;

	results: any = [];

	debouncedSearch: any;

	constructor() {
		super();
		this.debouncedSearch = debounce(this.handleSearch, 300);
	}

	async mounted() {
		this.entityDescription = await this.model.getDescription();
		if (this.initialSort) {
			this.currentSortDesc = this.initialSort.direction === "DESC";
			this.currentSortKey = this.initialSort.key;
		} else {
			this.currentSortDesc = false;
			this.currentSortKey =
				(
					this.tableFields.find(field => field.defaultSort) ||
					this.tableFields.find(field => !this.specialKeys.includes(field.key))
				)?.key ?? "";
		}
	}

	getFieldsFromColumns(columns: (string | { key: string; name: string; valueTransform: Function })[]) {
		if (!this.entityDescription) {
			return [];
		}
		return columns.map(fieldDefinition => {
			const field =
				this.entityDescription.find(
					(field: any) => field.key === (typeof fieldDefinition === "string" ? fieldDefinition : fieldDefinition.key),
				) || {};
			if (typeof fieldDefinition !== "string") {
				return { ...field, valueTransform: fieldDefinition.valueTransform, name: fieldDefinition.name }; // if column definition was passed, copy its properties to field
			}
			return field;
		});
	}

	get tableFields(): FieldDescription[] {
		const fields: FieldDescription[] = this.getFieldsFromColumns(this.columns).map(field => ({
			...field,
			key: field.key,
			label: field.name,
			sortable: true,
		}));
		if (this.multiple) {
			fields.unshift({ key: "selected", label: "Selecionar", sortable: false });
		}
		if (this.showRowNumber) {
			fields.unshift({ key: "rowNumber", label: "No.", sortable: false });
		}
		if (this.actions?.length) {
			fields.push({ key: "actions", label: "", sortable: false });
		}
		return fields;
	}

	get exportFields(): any[] {
		return this.getFieldsFromColumns(this.exportColumns || this.columns);
	}

	get tableItems() {
		return this.results.map((rowData: any) =>
			Object.fromEntries(
				[...this.tableFields, { key: "id" }].map(tableField => [
					tableField.key,
					this.getFieldContent(tableField, rowData),
				]),
			),
		);
	}

	getFieldContent(field: any, rowData: any) {
		if (field.valueTransform) {
			return field.valueTransform(rowData[field.key]); // transform value with given function
		}
		switch (field.kind) {
			case "select":
				return rowData[field.key]
					? field.options.find((option: any) => option.value === rowData[field.key])?.name
					: "-";
			case "datetime":
				return rowData[field.key] ? format(parseISO(rowData[field.key]), "dd/MM/yyyy HH:mm") : "-";
			case "date":
				return rowData[field.key] ? format(parseISO(rowData[field.key]), "dd/MM/yyyy") : "-";
			case "relation":
				return rowData[`${field.key}.name`];
		}
		return rowData[field.key];
	}

	getQueryParameters(fields: any[]) {
		// get joinable fields along with first level of required joins
		let joinFields = this.entityDescription.filter(
			(fieldDescription: any) =>
				fieldDescription.kind === "relation" &&
				fields.find(field => fieldDescription.key === field.key || fieldDescription.key === field.join),
		);
		let relatedFieldsToJoin = [];
		do {
			// find join dependencies that have not yet been added
			relatedFieldsToJoin = joinFields
				.filter(
					(joinedField: any) => joinedField.join && !joinFields.find((field: any) => field.key === joinedField.join),
				)
				.map((joinedField: any) =>
					this.entityDescription.find((fieldDescription: any) => fieldDescription.key === joinedField.join),
				);
			// add fields to start of joinFields
			joinFields = [...relatedFieldsToJoin, ...joinFields];
		} while (relatedFieldsToJoin.length);

		const filter: any = [];

		this.tableFields
			// filtra as colunas da tabela que contenham a palavra do currentSearchTerm
			.filter(field => field.kind !== "relation" && !this.specialKeys.includes(field.key))
			.forEach(field => {
				if (field.kind === "select" && field.options) {
					// como os campos de select são enum no backend, dentro do banco de dados eles são armazenados em inglês, por isso é necessária a conversão de português para inglês abaixo
					field.options.forEach((option: any) => {
						const currentSearchTerm = removeDiacritics(this.currentSearchTerm);
						const optionName = removeDiacritics(option.name);

						if (optionName.includes(currentSearchTerm)) {
							filter.push({
								[field.key]: {
									$cont: option.value,
								},
							});
						}
					});
				} else {
					filter.push({
						[field.key]: {
							$cont: this.currentSearchTerm,
						},
					});
				}
			});

		return {
			sort: [
				{
					field: this.currentSortKey,
					order: this.currentSortDesc ? "DESC" : "ASC",
				},
			],
			search: {
				...this.filter,
				$or: filter,
			},
			join: joinFields.map((fieldDescription: any) => ({ field: fieldDescription.key })),
		};
	}

	async handleSearch() {
		if (!this.currentSortKey) {
			return;
		}
		this.isTableLoading = true;
		try {
			const response = await this.model.search({
				...this.getQueryParameters(this.tableFields),
				page: this.currentPage,
				limit: this.pageLimit,
			});
			// se a página selecionada não existe, retorna para primeira página e busca novamente
			if (response.page > response.pageCount) {
				this.currentPage = 1;
				await this.handleSearch();
				return;
			}
			this.currentTotal = response.total;
			// adiciona numeração sequencial nas linhas
			if (this.showRowNumber) {
				response.data.forEach((result: any, resultIndex: number) => {
					result.rowNumber = (this.currentPage - 1) * this.pageLimit + resultIndex + 1;
				});
			}

			this.results = response.data;
		} catch (error) {
			console.error(error);
		}
		this.isTableLoading = false;
	}

	async exportSearch() {
		this.$store.dispatch("app/showLoading");
		try {
			const exportData = await this.model.search(this.getQueryParameters(this.exportFields));
			if (!exportData.length) {
				return;
			}
			// replace header names and translate select options
			const xlsData = exportData.map((row: any) =>
				this.exportFields.reduce(
					(processedRow, field) => ({
						...processedRow,
						[field.name]: this.getFieldContent(field, row),
					}),
					{},
				),
			);
			const exportWS = XLSX.utils.json_to_sheet(xlsData, { header: this.exportFields.map(field => field.name) });
			const wb = XLSX.utils.book_new();
			const title = this.title || "Relatório";
			XLSX.utils.book_append_sheet(wb, exportWS, title);
			await XLSX.writeFile(
				wb,
				`${slugify(title, { lower: true, strict: true })}_${format(new Date(), "yyyyMMdd_HHmmss")}.xlsx`,
			);
		} catch (error) {
			console.error(error);
		}
		this.$store.dispatch("app/hideLoading");
	}

	create() {
		this.$router.push(`${this.$router.currentRoute.path}/novo`);
	}

	@Watch("currentPage")
	@Watch("currentSortDesc")
	@Watch("currentSortKey")
	@Watch("currentSearchTerm")
	async handlePaginationSortingAndSearch() {
		this.debouncedSearch();
	}

	openRow(row: any) {
		if (!this.preventNavigation && row.id) {
			this.$router.push(`${this.$router.currentRoute.path}/${row.id}`);
		}
	}

	runAction(event: any, action: any, entityData: any) {
		event.stopPropagation();
		action(entityData);
	}

	async refresh() {
		this.results = []; // clear results
		this.debouncedSearch();
	}
}
